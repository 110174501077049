const A = JSON.parse(MA)
const SW = document.querySelectorAll('.maw')
const MODE = 'off'

const forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i])
    }
};

const randId = function (v) {
    return Math.floor(Math.random() * v.length)
}

const getNewAd = function (a, oldId) {
    let rand = randId(a)
    let n = a[rand]

    if (a.length > 1) {
        if (parseInt(oldId) === n.id) {
            return getNewAd(a, oldId)
        }
    }
    return n
}

// inject for single slot: Javascript Injections
window.maRun = function(key) {
    let maw = document.querySelectorAll('.maw');
    maw.forEach((itm) => {
        let k = itm.getAttribute('data-ma-key');
        if (k !== null) {
            if (key === k) {
                let a = A[k]
                if (a !== undefined) {
                    itm.classList.remove('hidden') // display slot only if there is an ad
                    const AD_MODE = itm.getAttribute('data-ma-mode')

                    let o = ''
                    for (const [i, v] of Object.entries(a)) {
                        let rand = randId(v)

                        if ('paralax' === AD_MODE) {

                            o += '<div class="ma ma-' + i + ' bg-fixed bg-no-repeat bg-cover bg-center w-full h-full" style="background-image: url(' + v[rand].src + ')">'
                            o += '</div>'

                        } else {

                            let width = ''
                            if ('normal' === AD_MODE) {
                                width = 'width: ' + v[rand].width + 'px;'
                            }
                            o += '<div class="ma ma-' + i + '" style="max-width: 100%;' + width + '" data-ma-id="' + v[rand].id + '" data-ma-key="' + k + '" data-ma-type="' + i + '">'
                            if (v[rand].link !== null) {
                                o += '<a href="' + v[rand].link + '" target="_blank">'
                            }
                            o += '<img src="' + v[rand].src + '" width="' + v[rand].width + '">'
                            if (v[rand].link !== null) {
                                o += '</a>'
                            }
                            o += '</div>'
                        }
                    }
                    itm.innerHTML = o
                }
            }
        }
    });
}

// inject all ads
forEach(SW, function (i, sw) {
    let k = sw.getAttribute('data-ma-key')
    if (k !== null) {
        let a = A[k]
        if (a !== undefined) {
            sw.classList.remove('hidden') // display slot only if there is an ad
            const AD_MODE = sw.getAttribute('data-ma-mode') // introduce ad modes

            let o = ''
            for (const [i, v] of Object.entries(a)) {
                let rand = randId(v)

                if ('paralax' === AD_MODE) {

                    o += '<div class="ma ma-' + i + ' bg-fixed bg-no-repeat bg-cover w-full h-full" style="background-image: url(' + v[rand].src + ')">'
                    o += '</div>'

                } else {

                    let width = ''
                    if ('normal' === AD_MODE) {
                        width = 'width: ' + v[rand].width + 'px;'
                    }
                    o += '<div class="ma ma-' + i + '" style="max-width: 100%;' + width + '" data-ma-id="' + v[rand].id + '" data-ma-key="' + k + '" data-ma-type="' + i + '">'
                    if (v[rand].link !== null) {
                        o += '<a href="' + v[rand].link + '" target="_blank">'
                    }
                    o += '<img src="' + v[rand].src + '" width="' + v[rand].width + '">'
                    if (v[rand].link !== null) {
                        o += '</a>'
                    }
                    o += '</div>'
                }

            }
            sw.innerHTML = o
        }
    }
})

// replace each ad at a time with  running all messes up rotation
window.ra = function (k) {
    setTimeout(function () {
        let a = A[k]
        if (a !== undefined) {
            let au = document.querySelector('.maw[data-ma-key="' + k + '"]')
            let cn = au.childNodes
            for (var i = cn.length - 1; i >= 0; i--) {
                let type = cn[i].getAttribute('data-ma-type')
                let ax = getNewAd(a[type], cn[i].getAttribute('data-ma-id'))

                cn[i].setAttribute('data-ma-id', ax.id)
                let f = ''

                f += '<img src="' + ax.src + '" width="' + ax.width + '">'

                if (ax.link === null) {
                    cn[i].innerHTML = f
                } else {
                    cn[i].innerHTML = '<a href="' + ax.link + '" target="_blank">' + f + '</a>'
                }
            }
        }
    }, 20000);
}

// splash ad
var order_name = $('.splash-ad').attr('data-order');
var splAd = localStorage.getItem('splash-' + order_name);

function showSplashAd() {
    $('.splash-ad').addClass('active');
    ga('send', 'event', 'splash-ad', 'impression', order_name, null, {
        'nonInteraction': 1
    });
}

if (order_name && null === splAd) {
    showSplashAd();
}

if (null !== splAd || 'forever' !== splAd) {
    var diffTime = 1000 * 60 * 60; // 30 mins
    if (new Date().getTime() - splAd > diffTime) {
        showSplashAd();
    }
}

$('.splash-ad-close').click(function () {
    var forever = $('.splash-ad input:checked').length > 0;
    if (forever) {
        localStorage.setItem('splash-' + order_name, 'forever');
    } else {
        localStorage.setItem('splash-' + order_name, new Date().getTime());
    }
    $('.splash-ad').removeClass('active');
});
	// end splash ad